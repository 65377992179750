import { createApiClient } from './zodios/ecommerce';
import { createApiClient as createMediaApiClient } from './zodios/media';
import { createApiClient as createAdminApiClient } from './zodios/admin';
import { eraseCookie, getCookie, setCookie } from '../cookie';
import { pluginToken } from './plugins/pluginToken';
import {
  setAuthSession,
  setMediaToken,
  COOKIE_TOKEN_MEDIA,
  COOKIE_TOKEN_API,
  COOKIE_EXPIRY,
} from '../repo/session';
import { resetRepositories } from '../repo';

export { schemas } from './zodios/ecommerce';
export { schemas as adminSchemas } from './zodios/admin';
import {
  SettingsKeys,
  setLocalConfig,
} from '@bamboo/ui-lib/src/hooks/localConfig';

function newApiClient() {
  if (typeof window !== 'undefined') {
    return createApiClient(process.env.NEXT_PUBLIC_API_PATH ?? '/');
  }
  let baseUrl =
    process.env.INTERNAL_API_PATH || process.env.NEXT_PUBLIC_API_PATH || '/';
  return createApiClient(baseUrl);
}

function newMediaApiClient() {
  if (typeof window !== 'undefined') {
    return createMediaApiClient(
      process.env.MEDIA_API_PATH ||
        process.env.NEXT_PUBLIC_MEDIA_API_PATH ||
        '/'
    );
  }
  let baseUrl =
    process.env.MEDIA_API_PATH || process.env.NEXT_PUBLIC_MEDIA_API_PATH || '';
  return createMediaApiClient(baseUrl);
}

function newAdminApiClient() {
  if (typeof window !== 'undefined') {
    return createAdminApiClient(process.env.ADMIN_API_PATH || '/');
  }
  let baseUrl = process.env.NEXT_PUBLIC_ADMIN_API_PATH || '';
  return createAdminApiClient(baseUrl);
}

const api = newApiClient();
const mediaApi = newMediaApiClient();
const adminApi = newAdminApiClient();

let uploadedMediaAuth = 'ecommerce';

export function useAdminMediaAuth() {
  uploadedMediaAuth = 'admin';
}

const after: ReturnType<typeof pluginToken> = {
  name: 'after',

  request: async (api, config) => {
    const conf = { ...config, withCredentials: true };
    return conf;
  },

  response: async (api, config, response) => {
    try {
      const {
        data: {
          item: { jwt, user, roles },
        },
      } = response.data;
      setCookie(
        COOKIE_TOKEN_API,
        jwt,
        new Date(new Date().getTime() + COOKIE_EXPIRY)
      );
      setAuthSession(jwt, user, roles);
    } catch (e) {
      console.warn(e);
    }
    return response;
  },
};

const afterLogout: ReturnType<typeof pluginToken> = {
  name: 'afterLogout',

  response: async (api, config, response) => {
    try {
      eraseCookie(COOKIE_TOKEN_API);
      eraseCookie(COOKIE_TOKEN_MEDIA);
      setAuthSession('', undefined, []);
      setLocalConfig(SettingsKeys.cartID, undefined);
      setLocalConfig(SettingsKeys.DownloadVideoUrl, undefined);
      resetRepositories();
    } catch (e) {
      console.warn(e);
    }
    return response;
  },
};

const mediaAfter: ReturnType<typeof pluginToken> = {
  name: 'after',

  request: async (api, config) => config,

  response: async (api, config, response) => {
    try {
      const jwt = response.data.data.item.jwt;
      setCookie(
        COOKIE_TOKEN_MEDIA,
        jwt,
        new Date(new Date().getTime() + COOKIE_EXPIRY)
      );
      setMediaToken(jwt);
    } catch (e) {
      console.warn(e);
    }
    return response;
  },
};

if (typeof window !== 'undefined') {
  api.use('post', '/api/onboarding/create-account', after);
  api.use('post', '/api/auth/signin', after);
  api.use('get', '/api/auth/refresh', after);
  api.use('post', '/api/auth/signout', afterLogout);
  api.use('post', '/api/auth/one-time-login', after);
  api.use(
    pluginToken({
      getToken: async () => getCookie(COOKIE_TOKEN_API) ?? undefined,
      renewToken: async () => {
        const {
          data: {
            item: { jwt },
          },
        } = await api.get('/api/auth/refresh');
        return jwt;
      },
    })
  );
  adminApi.use(
    pluginToken({
      getToken: async () => getCookie(COOKIE_TOKEN_API) ?? undefined,
      renewToken: async () => {
        const {
          data: {
            item: { jwt },
          },
        } = await api.get('/api/auth/refresh');
        return jwt;
      },
    })
  );

  api.use('post', '/api/auth-media', mediaAfter);
  adminApi.use('post', '/api/auth-media', mediaAfter);
  mediaApi.use(
    pluginToken({
      getToken: async () => getCookie(COOKIE_TOKEN_MEDIA) ?? undefined,
      renewToken: async () => {
        const auth =
          uploadedMediaAuth === 'admin'
            ? adminApi.mediaAuth
            : api.uploadedMediaAuth;
        const {
          data: {
            item: { jwt },
          },
        } = await auth({});
        return jwt;
      },
    })
  );
}

function initSession() {
  api
    .authRefresh()
    .then((r) => {})
    .catch((e) => {
      console.warn(e?.message);
    });
  setInterval(() => {
    api
      .authRefresh()
      .then((r) => {})
      .catch((e) => {
        console.warn(e?.message);
      });
  }, COOKIE_EXPIRY - 5000);
}

initSession();

export { api, mediaApi, adminApi };
